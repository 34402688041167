import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Navigation from "react-sticky-nav";
import './App.css';
import ScrollToTop from 'react-router-scroll-top';

import HomePage from './pages/HomePage';
import BookPage from './pages/BookPage';
import BlogPage from './pages/BlogPage';
import LessonsPage from './pages/LessonsPage';


import ChapterOneSlowAndSteady from './BookIndex/SlowAndSteadyBook/ChapterOneSlowAndSteady';
import ChapterTwoSlowAndSteady from './BookIndex/SlowAndSteadyBook/ChapterTwoSlowAndSteady';
import ChapterThreeSlowAndSteady from './BookIndex/SlowAndSteadyBook/ChapterThreeSlowAndSteady';
import ChapterOneRockAndRoll from './BookIndex/RockAndRollBook/ChapterOneRockAndRoll';
import ChapterTwoRockAndRoll from './BookIndex/RockAndRollBook/ChapterTwoRockAndRoll';
import ChapterThreeRockAndRoll from './BookIndex/RockAndRollBook/ChapterThreeRockAndRoll';
import ChapterFourRockAndRoll from './BookIndex/RockAndRollBook/ChapterFourRockAndRoll';

import ChapterOnePractice from './BookIndex/Practice/ChapterOnePractice';
import ChapterTwoPractice from './BookIndex/Practice/ChapterTwoPractice';
import ChapterThreePractice from './BookIndex/Practice/ChapterThreePractice';
import ChapterFourPractice from './BookIndex/Practice/ChapterFourPractice';

import CoreFourOne from './BookIndex/CoreFourBook/CoreFourOne';


class App extends React.Component {
constructor(props) {
super(props);
this.state = {
title: 'The Practicing Drummer',
headerLinks: [
{ title: 'Home', path: '/' },
{ title: 'Book', path: '/book' },
{ title: 'Blog', path: '/blog' },
{ title: 'Lessons', path: '/lesson' },
],

home: {
},

book: {
title: 'The Practicing Drummer',
subTitle: 'Table of Contents',
},

blog: {
title: 'Blog'
},

lesson: {
title: 'Virtual Lessons'
},

}
}

render() {

return(
<Router>





<Container className = "p-0" fluid = {true} >


<Navigation>
<Navbar className = "navbar" expand = "lg">
     <Navbar.Toggle className = "border-0" aria-controls = "navbar-toggle"  />
     <Navbar.Collapse id = "navbar-toggle"  >

     <Nav>

     <Link className = "nav-link" to="/" style={{color: "white"}}> The Practicing Drummer </Link>

</Nav>

<Nav className = "ml-auto">
     <Link className = "nav-link" to="BookPage" style={{color: "white"}}> Book </Link>
     <Link className = "nav-link" to = "BlogPage" style={{color: "white"}}> Blog </Link>
     <Link className = "nav-link" to = "LessonsPage" style={{color: "white"}}>Private Lessons</Link>

    </Nav>

      </Navbar.Collapse>

</Navbar>
</Navigation>



</Container>






<Route path = "/" exact render = {() => <HomePage title = {this.state.home.title}
subTitle = {this.state.home.subTitle} />} text = {this.state.home.text} />

<Route path = "/BookPage" render = {() => <BookPage title = {this.state.book.title}
subTitle = {this.state.book.subTitle} />} />

<Route path = "/BlogPage" render = {() => <BlogPage title = {this.state.blog.title} />} />

<Route path = "/LessonsPage" render = {() => <LessonsPage title = {this.state.lesson.title} />} />



<Route path="/ChapterOneSlowAndSteady" component={ChapterOneSlowAndSteady} />

<Route path="/ChapterTwoSlowAndSteady" component={ChapterTwoSlowAndSteady} />

<Route path="/ChapterThreeSlowAndSteady" component={ChapterThreeSlowAndSteady} />

<Route path="/ChapterOnePractice" component={ChapterOnePractice} />

<Route path="/ChapterTwoPractice" component={ChapterTwoPractice} />

<Route path="/ChapterThreePractice" component={ChapterThreePractice} />

<Route path="/ChapterFourPractice" component={ChapterFourPractice} />

<Route path="/CoreFourOne" component={CoreFourOne} />

<Route path="/ChapterOneRockAndRoll" component={ChapterOneRockAndRoll} />

<Route path="/ChapterTwoRockAndRoll" component={ChapterTwoRockAndRoll} />

<Route path="/ChapterThreeRockAndRoll" component={ChapterThreeRockAndRoll} />

<Route path="/ChapterFourRockAndRoll" component={ChapterFourRockAndRoll} />

<ScrollToTop />

</Router>


);
}
}
export default App;
