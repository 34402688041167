import React from 'react';
import Content from '../components/Content';
import { Link } from 'react-router-dom';
import Hero from '../components/Hero';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function BookPage(props)  {


        return (
<div>
<Content>


       <Hero title = {props.title} />

         <Hero subTitle = {props.subTitle}  />


  <h3>Slow & Steady</h3>

      <ul>
          <li><Link to="/ChapterOneSlowAndSteady">Chapter One</Link></li>
          <li><Link to="/ChapterTwoSlowAndSteady">Chapter Two</Link></li>
          <li><Link to="/ChapterThreeSlowAndSteady">Chapter Three</Link></li>
      </ul>

  <h3>Rock & Roll</h3>

      <ul>
          <li><Link to="/ChapterOneRockAndRoll">Chapter One</Link></li>
          <li><Link to="/ChapterTwoRockAndRoll">Chapter Two</Link></li>
          <li><Link to="/ChapterThreeRockAndRoll">Chapter Three</Link></li>
          <li><Link to="/ChapterFourRockAndRoll">Chapter Four</Link></li>
      </ul>

          <h3>The Beginner's Drum Book Practice</h3>
          <ul>
              <li><Link to="/ChapterOnePractice">Chapter One Practice</Link></li>
              <li><Link to="/ChapterTwoPractice">Chapter Two Practice</Link></li>
              <li><Link to="/ChapterThreePractice">Chapter Three Practice</Link></li>
              <li><Link to="/ChapterFourPractice">Chapter Four Practice</Link></li>
              </ul>

                <h3>The Core Four Drum Book</h3>

                    <ul>


                        <li><Link to="/CoreFourOne">The Core Four: Chapter One</Link></li>

                     </ul>


  </Content>
</div>






                   )}




export default BookPage;
