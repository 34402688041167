import React from 'react';
import ReactDOM from 'react-dom';
import '../App.css';
import Hero from '../components/Hero';
import { Link } from 'react-router-dom';
import Content from '../components/Content';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';




function LessonsPage(props) {
return (
   <div>
   <Content>
<Hero title = {props.title} />

<div  className = "intro">


<p>If you’re interested in taking your drumming to the next level I currently offer private one-on-one drum lessons over Zoom.</p>

<p>Lessons are 45 minutes long and cost $45. Flexible scheduling available. Lessons can be scheduled once per week or just occasionally. I can work around your schedule.</p>

<p><b>Unsure about online lessons?</b> Try your first one out for <b>FREE!</b> To work out any sound/technical issues and to see if online lessons are right for you, test it out free of charge. No commitment or catch!</p>

<p>To schedule your first <b>free</b> lesson or if you have any questions please send me a message at:</p>

<p><b>thepractingdrummer@gmail.com</b></p>




</div>












</Content>

 </div>


);
}

export default LessonsPage;